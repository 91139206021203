import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import test from "./test.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    test,
  },
});
