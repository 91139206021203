<template>
  <v-container fluid class="pa-12">
    <v-row class="text">
      <v-col>Sitio web creado por Cristóbal Espinoza.</v-col>
    </v-row>
    <v-row class="text">
      <v-col
        ><v-btn class="yellow" @click="buyMeACoffee"
          ><v-icon class="me-2">mdi-coffee</v-icon>Buy me a coffee</v-btn
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  methods: {
    buyMeACoffee() {
      window.open("https://www.buymeacoffee.com/caespinozaj", "_blank");
    },
  },
};
</script>
