<template>
  <v-container fluid class="pa-12">
    <div>Home</div>
  </v-container>
</template>
<script>
export default {
  name: "Home",
};
</script>
