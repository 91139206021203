<template>
  <v-theme-provider root>
    <v-app>
      <v-navigation-drawer v-if="!isMobile" permanent v-model="drawer" app>
        <v-list>
          <v-list-item
            v-for="(route, index) in $router.options.routes"
            :key="index"
            :to="route.path"
          >
            <v-list-item-content>{{ route.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-switch
          v-model="$vuetify.theme.dark"
          hint="This toggles the global state of the Vuetify theme"
          inset
          label="Vuetify Theme Dark"
          persistent-hint
        ></v-switch>
      </v-navigation-drawer>

      <v-app-bar v-if="isMobile" app bottom>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(route, index) in $router.options.routes"
              :key="index"
              :to="route.path"
            >
              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-toolbar-title>Mobile Navigation</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </v-theme-provider>
</template>

<script>
export default {
  name: "App",
  created() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
  },
  data() {
    return {
      drawer: false,
      isMobile: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
    };
  },
};
</script>
