const getDefaultState = () => ({
  variable: "var1",
});
const state = getDefaultState();
const getters = {
  variable: ({ variable }) => variable,
};
const mutations = {
  setVariable(state, variable) {
    state[variable] = variable;
  },
};
const actions = {
  // eslint-disable-next-line
  reset({ state }, {}) {
    Object.assign(state, getDefaultState());
  },
  setVariable({ commit }, variable) {
    commit("setVariable", variable);
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
